import React from 'react'
import { Link, graphql } from 'gatsby'
import classnames from 'classnames'
import { RichText } from 'prismic-reactjs'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

import HomePodcasts from '../../components/HomePodcasts'
import ArticlesColumn from '../../components/ArticlesColumn'
import arrow from '../../images/cta-arrow.svg'
import latestArticleImage from '../../images/related-3.jpg'
import TrendsColumn from '../../components/TrendsColumn'
import { createRichTextSerializer } from '../../utils/prismic'

import useImageModalState from '../../useImageModalState'
import PageHeader from '../../components/PageHeader'

export const query = graphql`
  query {
    prismic {
      latestArticles: allArticles(
        sortBy: meta_firstPublicationDate_DESC
        first: 20
        where: { focusarea: "XOxwdBAAAGYAaOVY" }
      ) {
        totalCount
        edges {
          cursor
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            image
            is_hidden
            focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                }
                name
              }
            }
            body {
              ... on PRISMIC_ArticleBodyText {
                primary {
                  text
                }
              }
            }
          }
        }
      }

      latestPodcasts: allPodcasts(
        sortBy: meta_firstPublicationDate_DESC
        first: 20
        where: { focusarea: "XOxwdBAAAGYAaOVY" }
      ) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            is_hidden
            focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                  id
                }
                name
              }
            }
            expert {
              ... on PRISMIC_Expert {
                _meta {
                  uid
                }
                name
                title
                photo
              }
            }
          }
        }
      }

      latestTrends: allTrends(
        sortBy: meta_firstPublicationDate_DESC
        first: 3
        where: { focusarea: "XOxwdBAAAGYAaOVY" }
      ) {
        edges {
          node {
            _meta {
              firstPublicationDate
            }
            title
            focusArea: focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                }
              }
            }
            description: excerpt
            link {
              ... on PRISMIC__ExternalLink {
                url
                _linkType
              }
            }
            linkLabel: link_label
            thumbnail
            linkLogo: link_logo
          }
        }
      }
      focus_area_page(uid: "preventive-healthcare", lang: "en-us") {
        title
        description
        body {
          ... on PRISMIC_Focus_area_pageBodyOne_column {
            type
            primary {
              body_text
            }
          }
          ... on PRISMIC_Focus_area_pageBodyTwo_columns {
            type
            primary {
              body_text
              body_image
            }
          }
          ... on PRISMIC_Focus_area_pageBodyInfographic {
            type
            primary {
              infographic_title
              infographic_image
              infographic_description
              infographic_source
            }
          }
        }
      }
    }

    allFile(filter: { name: { glob: "trend__logo--*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed: fixed(quality: 100, grayscale: true, height: 24) {
              src
            }
          }
        }
      }
    }
  }
`

const articleSerializer = createRichTextSerializer({
  paragraphClassname: ({ index }) =>
    classnames('text-base font-sans font-normal leading-loose', {
      'mt-16': index === 0,
      'mt-4': index > 0,
    }),
})
const secondArticleSerializer = createRichTextSerializer({
  paragraphClassname: ({ index }) =>
    classnames('text-base font-sans font-normal leading-loose mt-4'),
})

const PreventiveHealthcarePage = props => {
  const {
    data: {
      prismic: {
        latestPodcasts: latestPodcastsRaw,
        latestArticles: latestArticlesCollection,
        latestTrends: latestTrendsCollection,
      },
    },
  } = props

  const latestPodcasts = latestPodcastsRaw.edges
    .map(edge => edge.node)
    .filter(node => Boolean(node.expert) && node.is_hidden !== 'HIDDEN')
    .slice(0, 3)

  const latestArticlesRaw = latestArticlesCollection.edges
    .map(edge => edge.node)
    .filter(article => article.is_hidden !== 'HIDDEN')
    .slice(0, 3)

  const calculatedExcerpts = latestArticlesRaw.reduce((acc, article) => {
    const firstParagraph =
      article.body && article.body[0].primary
        ? article.body[0].primary.text
        : null

    if (!firstParagraph) {
      return { ...acc, [article._meta.uid]: '' }
    }

    const full = article.body ? RichText.asText(firstParagraph) : ''

    return {
      ...acc,
      [article._meta.uid]:
        full.slice(0, 160) +
        (full.slice(0, 160).substr(-1) === '.' ? '' : '...'),
    }
  }, {})

  const latestArticles = latestArticlesRaw.map(article => ({
    uid: article._meta.uid,
    title: article.title,
    description: article.excerpt || calculatedExcerpts[article._meta.uid],
    image: article.image ? article.image.url : latestArticleImage,
    createdAt: article._meta.firstPublicationDate,
    category: article.focusarea,
  }))

  const latestTrendsRaw = latestTrendsCollection.edges

  const latestTrends = latestTrendsRaw.map(
    ({
      node: {
        title,
        description,
        link,
        focusArea,
        linkLabel,
        linkLogo,
        ...node
      },
    }) => {
      const whereToSlice =
        description.slice(0, 160).substr(-1) === ' ' ? 159 : 160
      const excerpt =
        description.length > 160
          ? description.slice(0, whereToSlice) +
            (description.slice(0, whereToSlice).substr(-1) === '.' ? '' : '...')
          : description

      return {
        title,
        description: excerpt,
        focusArea,
        image: node.thumbnail ? node.thumbnail.url : undefined,
        link: link ? link.url : '',
        linkLabel,
        linkLogo,
        createdAt: node._meta.firstPublicationDate,
      }
    }
  )

  const [
    { isImageModalOpen, imageModalImage },
    setImageModalState,
  ] = useImageModalState()

  const result = props.data.prismic.focus_area_page

  let infographics = 0
  let paragraphSlices = 0

  const logos = props.data.allFile.edges.reduce((all, edge) => {
    return {
      ...all,
      [edge.node.name]: edge.node.childImageSharp.fixed.src,
    }
  }, {})

  return (
    <Layout>
      <SEO title="Preventive Healthcare" keywords={[`longevity`]} />

      <PageHeader
        titles={[result.title]}
        descriptions={[result.description]}
        lineColorClass="bg-preventive-healthcare"
      />

      <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto font-serif text-blue-dianne">
        {result.body.map((slice, slicesIndex) => {
          if (slice.type === 'one_column') {
            paragraphSlices += 1
            return (
              <React.Fragment key={slicesIndex}>
                {RichText.render(
                  slice.primary.body_text,
                  undefined,
                  paragraphSlices === 1
                    ? articleSerializer
                    : secondArticleSerializer
                )}
              </React.Fragment>
            )
          }

          if (slice.type === 'two_columns') {
            paragraphSlices += 1
            return (
              <div className="clearfix">
                <div className="lg:w-1/2 lg:pl-8 lg:float-right">
                  {slice.primary.body_image && slice.primary.body_image.url && (
                    <img
                      src={slice.primary.body_image.url}
                      alt=""
                      className="block"
                    />
                  )}
                </div>
                {RichText.render(
                  slice.primary.body_text,
                  undefined,
                  paragraphSlices === 1
                    ? articleSerializer
                    : secondArticleSerializer
                )}
              </div>
            )
          }

          if (
            slice.type === 'infographic' &&
            slice.primary.infographic_image &&
            slice.primary.infographic_image.url
          ) {
            infographics += 1
            return (
              <div
                className={classnames({
                  'mt-24': infographics === 1,
                  'mt-12': infographics > 1,
                })}
                key={slicesIndex}
              >
                {slice.primary.infographic_title && (
                  <h2 className="font-normal font-serif text-lg lg:text-xl mb-8">
                    {slice.primary.infographic_title}
                  </h2>
                )}
                {slice.primary.infographic_description && (
                  <p className="text-base font-sans font-normal leading-loose mt-4">
                    {slice.primary.infographic_description}
                  </p>
                )}
                <button
                  onClick={() => {
                    setImageModalState({
                      isImageModalOpen: true,
                      imageModalImage: slice.primary.infographic_image.url,
                    })
                  }}
                >
                  <img
                    src={slice.primary.infographic_image.url}
                    alt="infographic"
                    className="w-full"
                  />
                </button>
                {slice.primary.infographic_source && (
                  <p className="text-gothic font-sans text-right text-sm italic mt-4">
                    Source: {slice.primary.infographic_source}
                  </p>
                )}
              </div>
            )
          }

          return null
        })}
      </div>

      <ModalGateway>
        {isImageModalOpen && (
          <Modal
            onClose={() => setImageModalState({ isImageModalOpen: false })}
            allowFullscreen={false}
          >
            <Carousel views={[{ src: imageModalImage }]} />
          </Modal>
        )}
      </ModalGateway>

      <div className="max-w-5xl mx-auto">
        <HomePodcasts
          sectionTitle="Related Podcasts"
          allLink="/podcasts"
          podcasts={latestPodcasts}
          showAllLink
        />
      </div>

      <div className="container mx-auto my-24 lg:my-32 font-serif text-blue-dianne">
        <h2 className="font-normal font-serif text-xl lg:text-2xl mx-8 lg:mx-0">
          Latest Insights
        </h2>
        <ArticlesColumn articles={latestArticles} />
        <div className="flex mx-8 lg:mx-auto mt-16 justify-center">
          <Link
            to="/insights/preventive-healthcare"
            className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
          >
            <span className="cta-ie11-fix">Show all Insights</span>{' '}
            <img src={arrow} alt="" className="ml-8 h-6" />
          </Link>
        </div>
      </div>
      <div className="container mx-auto my-24 lg:my-32 font-serif text-blue-dianne">
        <h2 className="font-normal font-serif text-xl lg:text-2xl mx-8 lg:mx-0">
          Latest Trends
        </h2>
        <TrendsColumn trends={latestTrends} logos={logos} />
        <div className="flex mx-8 lg:mx-auto mt-16 justify-center">
          <Link
            to="/trends"
            className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
          >
            <span className="cta-ie11-fix">Show all Trends</span>{' '}
            <img src={arrow} alt="" className="ml-8 h-6" />
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default PreventiveHealthcarePage
